import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons'
import siteConfig from '../../config/siteConfig'

const styles = theme => ({
    wholeContent:{
        paddingTop: theme.spacing.unit*4,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing.unit*8,
            paddingRight: theme.spacing.unit*8,
        },
        [theme.breakpoints.only('sm')]: {
            paddingLeft: theme.spacing.unit*4,
            paddingRight: theme.spacing.unit*4,
        },
        textAlign: 'center',
    },
    theTitle: {
        textTransform: 'uppercase',
        fontSize: '3rem'
    },
    socialIcons: {
        '& div': {
            padding: theme.spacing.unit*5
        },
        '& a': {
            textDecoration: 'none',
        },
        '& > a': {
            display: 'block',
            margin: 'auto',
            height: theme.spacing.unit*20,
        },
    },
    fbIcon: {
        '&:hover': {
            color: '#A20471',
        },
        color: '#3b5998'
    },
    twIcon: {
        '&:hover': {
            color: '#A20471',
        },
        color: '#00aced'
    },
    liIcon: {
        '&:hover': {
            color: '#A20471',
        },
        color: '#007fb1'
    },
    emIcon: {
        '&:hover': {
            color: '#A20471',
        },
        color: '#7f7f7f'
    },
    gtIcon: {
        '&:hover': {
            color: '#A20471',
        },
        color: 'black'
    }
})
const Contact = ({ classes, location }) => {
    const links = siteConfig.userLinks
    return (
        <Layout isPage={true}>
            <Helmet
                title='Contact me'
                link={[
                    { rel: 'canonical', href: `${location?location.href:null}` }
                ]}
            />
            <div className={classes.wholeContent}>
                <div className={classes.socialIcons}>
                    <div>
                        <a className={classes.fbIcon} title={links[4].label} href={links[4].url} target='_blank'>
                            <FontAwesomeIcon icon={faFacebookF} className={classes.fbIcon} size='6x' />
                        </a>
                    </div>
                    <div>
                        <a className={classes.liIcon} title={links[1].label} href={links[1].url} target='_blank'>
                            <FontAwesomeIcon icon={faLinkedinIn} className={classes.liIcon} size='6x' />
                        </a>
                    </div>
                    <div>
                        <a className={classes.twIcon} title={links[2].label} href={links[2].url} target='_blank'>
                            <FontAwesomeIcon icon={faTwitter} className={classes.twIcon} size='6x' />
                        </a>
                    </div>
                    <div>
                        <a className={classes.emIcon} title={links[3].label} href={links[3].url} target='_blank'>
                            <FontAwesomeIcon icon={faEnvelope} className={classes.emIcon} size='6x' />
                        </a>
                    </div>
                    <div>
                        <a className={classes.gtIcon} title={links[0].label} href={links[0].url} target='_blank'>
                            <FontAwesomeIcon icon={faGithub} className={classes.gtIcon} size='6x' />
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Contact)